import { mapGetters } from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ApiService from "@/core/services/api.service";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },

  name: "donation-goal",
  data() {
    return {
      valid: false,
      isSaving: false,
      donationGoal: {
        id: null,
        title: "",
        titleMe: "",
        titleDe: "",
        description: "",
        descriptionMe: "",
        descriptionDe: "",
        bannerUrl: "",
        urlDetails: "",
        urlDonation: "",
        base64Image: null,
        globalGoalId: null
      },
      donationGoalId: null,
      tabIndex: 0,
      globalGoals: [],
      search: "",
      goalsAreLoading: false,
      requiredRule: [v => !!v || "Field is required"]
    };
  },
  created() {
    this.donationGoalId = this.$route.params.donationGoalId;
    let title =
      this.donationGoalId != null
        ? "Donation goal details"
        : "Insert new donation goal";
    this.$store.dispatch(SET_BREADCRUMB, [{ title: title }]);
    if (this.donationGoalId != null) {
      ApiService.get(`api/donation-goal`, `${this.donationGoalId}`)
        .then(response => {
          this.$log.debug("Donation goal: ", response.data);
          this.donationGoal = response.data;
          this.donationGoal.globalGoalId = response.data != null && response.data.globalGoal != null ? response.data.globalGoal.id : null;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    }
    this.searchGlobalGoals();
  },
  methods: {
    previousState() {
      this.$router.go(-1);
    },
    endsWith(suffix, str) {
      return str.indexOf(suffix, str.length - suffix.length) !== -1;
    },
    paddingSize(value) {
      if (this.endsWith("==", value)) {
        return 2;
      }
      if (this.endsWith("=", value)) {
        return 1;
      }
      return 0;
    },
    size(value) {
      return (value.length / 4) * 3 - this.paddingSize(value);
    },
    byteSize(base64String) {
      return this.formatAsBytes(this.size(base64String));
    },
    formatAsBytes(size) {
      return size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " bytes";
    },
    handleImage(event) {
      if (event && event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        // this.toBase64(file);
        this.toBase64(file, base64Data => {
          this.donationGoal.base64Image = base64Data;
        });
      }
    },
    toBase64(file, cb) {
      const fileReader = new FileReader();
      fileReader.onload = e => {
        const base64Data = e.target.result;
        cb(base64Data);
      };
      fileReader.readAsDataURL(file);
    },
    searchGlobalGoals() {

      // Items have already been loaded
      if (this.globalGoals.length > 0) return;

      // Items have already been requested
      if (this.goalsAreLoading) return;

      this.goalsAreLoading = true;
      ApiService.query("api/donation-goal/global-goals/dropdown-list")
        .then(res => {
          this.globalGoals = res.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.goalsAreLoading = false));
    },
    validateAndSave(){
      if (this.$refs.form.validate()){
        this.saveDonationGoal(this.donationGoal);
      }
    },
    saveDonationGoal(donationGoal) {
      this.isSaving = true;
      this.loading = true;
      if (donationGoal.id != null) {
        ApiService.put(`api/donation-goal`, donationGoal)
          .then(response => {
            this.$log.debug("Donation goal updated: " + response);
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
            this.isSaving = false;
            this.previousState();
          });
      } else {
        ApiService.post(`api/donation-goal`, donationGoal)
          .then(response => {
            this.$log.debug("Donation goal created: " + response);
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
            this.isSaving = false;
            this.previousState();
          });
      }
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit("input", value);
        }
      }
    }
  }
};
